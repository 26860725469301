import React from "react"
import styled from "styled-components"

import DotBackground from '../components/dotbackground';
import RoundButton from '../components/roundbutton';

import content from '../data/static-content.js';

import { behaviourTag } from '../shared/analytics';

/* ---------------------------------------------- */
/* ---------------------------------------------- */

const ContactsSection = styled.div`
  position         : relative;
  background-color : #252F3A;
  padding-top      : 11rem;
  padding-bottom   : 11rem;
  overflow         : hidden;
  min-height       : 10rem;
  display          : flex;
  flex-direction   : column;
  justify-content  : center;
  align-items      : center;
`;

const ContactsSectionBackground = styled(DotBackground)`
  position : absolute;
  left     : 10%;
  top      : 0rem;
  width    : 50%;
  height   : 100%;
`;

const ContactsLogo = styled.img`
  width : 21.1rem;
  height : 11rem;
  align-self : flex-start;
  margin-left : 10rem;
  position : relative;

  @media screen and (max-width: 425px) {
    margin-left : 0rem;
    transform : translateX(-25%);
  }
`;

const ContactsTitle = styled.h2`
  color : white;

  @media screen and (max-width: 425px) {
    width : 75%;
  }
`;

const ContactsContent = styled.div`
  width : 100rem;
  position : relative;

  @media screen and (max-width: 800px) {
    width : 65rem;
  }

  @media screen and (max-width: 425px) {
    width : 100%;
    display : flex;
    flex-direction : column;
    align-items : center;
  }
`

const ContactsRow = styled.div`
  display : flex;
  flex-direction : row;
  justify-content : space-between;

  @media screen and (max-width: 800px) {
    flex-direction : column;
  }

  /*@media screen and (max-width: 425px) {
    width : 75%;
  }*/
`;

const ContactRowSection = styled.div`
  display : flex;
  flex-direction : row;
  justify-content : space-between;

  &:nth-child(1) {
    margin-right : 6.66rem;
  }

  @media screen and (max-width: 800px) {
    &:nth-child(1) {
      margin-right : 0rem;
    }

    &:nth-child(2) {
      margin-top : 4rem;
    }
  }

  @media screen and (max-width: 425px) {
    flex-direction : column;
    align-items : center;
  }
`;

const ContactsInputContainer = styled.div`
  width : 20rem;
  display : flex;
  flex-direction : column;

  &:nth-child(1) {
    margin-right : 6.66rem;
  }

  @media screen and (min-width: 426px) and (max-width: 800px) {
    width : 40rem;
  }

  @media screen and (max-width: 425px) {
    width : 100%;

    &:nth-child(1) {
      margin-right : 0rem;
    }

    &:nth-child(2) {
      margin-top : 4rem;
    }
  }

`;

const ContactsLabel = styled.label`
  color : white;
  font-size : 1.8rem;
`

const ContactsInput = styled.input`
  border : none;
  border-bottom : 1px solid rgba(255,255,255,0.5);
  background-color : transparent;
  color : white;
  font-size : 1.8rem;
  padding-top : 1rem;
  outline : none;
`

const ContactsMessageRow = styled.div`
  margin-top : 4rem;
  display : flex;
  flex-direction : column;

  /*@media screen and (max-width: 425px) {
    width : 75%;
  }*/
`;

const ContactsMessage = styled.textarea`
  border : none;
  border-bottom : 1px solid rgba(255,255,255,0.5);
  background-color : transparent;
  color : white;
  font-size : 1.8rem;
  padding-top : 1rem;
  outline : none;
`

const SendButtonRow = styled.div`
  margin-top : 6rem;
  display : flex;
  flex-direction : row;
  justify-content : flex-end;

  @media screen and (max-width: 425px) {
    align-self : flex-end;
    /*margin-right : 12.5%;*/
  }
`;


const Contacts = props => (
  <ContactsSection>
      <ContactsSectionBackground />
      <ContactsLogo src={require('../images/logo-90deg.svg')}/>
      <ContactsContent>
        <form name="contact" method="POST" data-netlify="true" action="/message-sent">
          <input type="hidden" name="form-name" value="contact" />
          <ContactsTitle>
            {content.home.contacts.title}
          </ContactsTitle>
          <ContactsRow>
            <ContactRowSection>
              <ContactsInputContainer>
                <ContactsLabel htmlFor="contact-nom">Nom</ContactsLabel>
                <ContactsInput id="contact-nom" name="nom" type="text" />
              </ContactsInputContainer>
              <ContactsInputContainer>
                <ContactsLabel htmlFor="contact-prenom">Prénom</ContactsLabel>
                <ContactsInput id="contact-prenom" name="prenom" type="text" />
              </ContactsInputContainer>
            </ContactRowSection>
            <ContactRowSection>
              <ContactsInputContainer>
                <ContactsLabel htmlFor="contact-email">Email</ContactsLabel>
                <ContactsInput id="contact-email" name="email" type="email" required/>
              </ContactsInputContainer>
              <ContactsInputContainer>
                <ContactsLabel htmlFor="contact-telephone">Téléphone</ContactsLabel>
                <ContactsInput id="contact-telephone" name="telephone" type="telephone" />
              </ContactsInputContainer>
            </ContactRowSection>
          </ContactsRow>
          <ContactsMessageRow>
            <ContactsLabel htmlFor="contact-message">Message</ContactsLabel>
            <ContactsMessage id="contact-message" name="message" required/>
          </ContactsMessageRow>
          <SendButtonRow>
            <RoundButton
              type="submit"
              onClick={() => behaviourTag('contact-submission')}
              onKeyPress={() => behaviourTag('contact-submission')}
            >
              {content.home.contacts.button}
            </RoundButton>
          </SendButtonRow>
        </form>
      </ContactsContent>
  </ContactsSection>
);

export default Contacts;
