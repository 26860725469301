import React from "react";
import styled from "styled-components"

import { behaviourTag } from '../../shared/analytics';

const AnimatedSvg = styled.svg`
  /***************************************
   Animations keyframes
   */

  @keyframes left-right {
    0% {
      transform: translate3d(1rem, 0, 0);
    }

    50% {
      transform: translate3d(-1rem, 0, 0);
    }

    100% {
      transform: translate3d(1rem, 0, 0);
    }
  }

  @keyframes left-right-end {
    0% {
      transform: translate3d(1rem, 0, 0);
    }

    50% {
      transform: translate3d(-1rem, 0, 0);
    }

    100% {
      transform: translate3d(0rem, 0, 0);
    }
  }

  @keyframes rotate-ticks {
    0%, 35% {
      transform: rotate(3deg);
    }

    50%, 85% {
      transform: rotate(-3deg);
    }

    100% {
      transform: rotate(3deg);
    }
  }

  @keyframes shake {
    0%, 40% {
      transform: translate3d(-0.05rem, 0, 0);
    }

    5%, 35% {
      transform: translate3d(0.1rem, 0, 0);
    }

    10%, 20%, 30% {
      transform: translate3d(-0.2rem, 0, 0);
    }

    15%, 25% {
      transform: translate3d(0.2rem, 0, 0);
    }
  }

  @keyframes jello-horizontal {
    0% {
      transform: scale3d(1, 1, 1);
    }
    30% {
      transform: scale3d(1.05, 0.95, 1);
    }
    40% {
      transform: scale3d(0.95, 1.05, 1);
    }
    50% {
      transform: scale3d(1.03, 0.97, 1);
    }
    65% {
      transform: scale3d(0.99, 1.01, 1);
    }
    75% {
      transform: scale3d(1.01, 0.99, 1);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes jello-horizontal-bounce {
    0% {
      transform: scale3d(1, 1, 1);
    }
    10% {
      transform: scale3d(1.05, 0.95, 1);
    }
    20% {
      transform: scale3d(0.95, 1.05, 1);
    }
    30% {
      transform: scale3d(1.03, 0.97, 1);
    }
    45% {
      transform: scale3d(0.99, 1.01, 1);
    }
    55% {
      transform: scale3d(1.01, 0.99, 1);
    }
    80% {
      transform: scale3d(1, 1, 1);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
  }



  /***************************************
   Animated elements
   */

  & #bridge {
    animation: shake 2s cubic-bezier(.36,.07,.19,.97) infinite both;
  }

  & #pink-dot {
    animation: left-right 2s 1s cubic-bezier(.36,.07,.19,.97) infinite both;
  }

  & #pink-bar {
    animation: rotate-ticks 2s cubic-bezier(.36,.07,.19,.97) infinite both;
    transform-origin : 50% 0%;
  }

  & #big-o, & #big-o-shadow {
    animation: jello-horizontal 2s 0.35s cubic-bezier(.36,.07,.19,.97) infinite both;
    transform-origin: 50% 60%;
  }

  & #blue-dot-halo, & #blue-dot {
    animation: jello-horizontal-bounce 2s 1.20s cubic-bezier(.36,.07,.19,.97) infinite both;
    transform-origin: 50% 95%;
  }



  /*
    Elements that hides on collapse
  */
  & #bridge, & #blue-dot-halo, & #pink-bar, & #big-o-shadow, & #white-circle {
    transition : opacity 0.4s ease;
  }

  &.collapsed #bridge, &.collapsed #blue-dot-halo, &.collapsed #pink-bar, &.collapsed #big-o-shadow, &.collapsed #white-circle {
    opacity : 0;
  }

  @media screen and (min-width: 426px) {
    &:hover #bridge, &:hover #blue-dot-halo, &:hover #pink-bar, &:hover #big-o-shadow, &:hover #white-circle {
      opacity : 0;
    }
  }


  /*
    Elements thats moves on collapse
  */
  #big-o-container {
    transition : transform 0.4s ease-in-out;
  }

  #pink-dot-container {
    transition : transform 0.5s ease-in-out;
  }

  #abstract-container {
    transition : transform 0.6s ease-in-out;
  }

  &.collapsed #big-o-container {
    transform : translateY(-50px);
  }

  &.collapsed #pink-dot-container {
    transform : translateY(330px) scale(1.2);
    transform-origin : top center;
  }

  &.collapsed #abstract-container {
    transform : translateY(-100px);
  }

  @media screen and (min-width: 426px) {
    &:hover #big-o-container {
      transform : translateY(-50px);
    }

    &:hover #pink-dot-container {
      transform : translateY(330px) scale(1.2);
      transform-origin : top center;
    }

    &:hover #abstract-container {
      transform : translateY(-100px);
    }
  }

`;

function Abstrait({className, collapsed}) {
  return (
    <AnimatedSvg
      className={className + (collapsed ? ' collapsed' : '')}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="296"
      height="457"
      viewBox="0 0 296 457"
      onClick={() => behaviourTag('abstract-click')}
      onMouseEnter={() => behaviourTag('abstract-hover')}
    >
      <defs>
        <pattern
          id="b"
          width="8.257"
          height="8.089"
          x="101.743"
          y="230.911"
          patternUnits="userSpaceOnUse"
        >
          <use transform="scale(.16851)" xlinkHref="#a"></use>
        </pattern>
        <image
          id="a"
          width="49"
          height="48"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADEAAAAwCAYAAAC4wJK5AAAABGdBTUEAALGN5fIAKQAAAMBJREFUaAXtlcERhSAMBRlrsCBKtCiPNmAH3i1A81RuyuSQA/y/byZmhExMFoGUEAQgAAEIQAACEIAABCAAAQiEExgt42S2mO2P17vGu1C2Kjez48U0ns2alkh/NVCa0nzTK6JfphRb84prVtoDteLLnOKalTZxKbTmFRemISzTnWh15vPGudJFNzG7vpqSN86ZLjbsJ04nIclmX8dsF/eEmpC0Il3f2FcXPCAAAQhAAAIQgAAEIAABCEAAAn9G4AQ56krgiOjFzAAAAABJRU5ErkJggg=="
        ></image>
      </defs>
      <g id="abstract-container" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-960 -191)">
          <g transform="translate(960 187)">
            <circle id="white-circle" cx="153.5" cy="311.5" r="71.5" fill="#FFF"></circle>
            <circle
              id="big-o-shadow"
              cx="181.5"
              cy="310.5"
              r="71.5"
              fill="url(#b)"
              opacity="0.489"
            ></circle>
            <path
              id="pink-bar"
              fill="#F75252"
              d="M56 79H251V110H56z"
              transform="rotate(-1 153.5 94.5)"
            ></path>
            <circle
              id="blue-dot-halo"
              cx="153.5"
              cy="427.5"
              r="32.5"
              stroke="#525DF4"
              transform="rotate(11 153.5 427.5)"
            ></circle>
            <circle
              id="blue-dot"
              cx="153.5"
              cy="427.5"
              r="24.5"
              fill="#525DF4"
              transform="rotate(11 153.5 427.5)"
            ></circle>
            <g id="pink-dot-container">
              <circle
                id="pink-dot"
                cx="153.5"
                cy="24.5"
                r="20.5"
                fill="#F75252"
                transform="rotate(11 153.5 24.5)"
              ></circle>
            </g>
            <path
              id="bridge"
              fill="#525DF4"
              d="M281.072 128L296 270.384l-44.574 4.696-.007-.068c-6.48-53.458-54.574-92.01-108.195-86.36-53.645 5.653-92.702 53.425-87.915 107.094L14.928 300 0 157.616 281.072 128z"
            ></path>
            <g id="big-o-container">
              <circle
                id="big-o"
                cx="153"
                cy="289"
                r="61"
                stroke="#252F3A"
                strokeWidth="40"
              ></circle>
            </g>
          </g>
        </g>
      </g>
    </AnimatedSvg>
  );
}

export default Abstrait;
