import React from "react";
import styled from "styled-components"

const AnimatedSvg = styled.svg`
  margin-left : -13px;

  /***************************************
   Animations keyframes
   */

   @keyframes web-pink-blink {
     0% {
       opacity: 1;
     }

     /* 3% {
       opacity: 0;
     } */

     6% {
       opacity: 0;
     }

     /* 9% {
       opacity: 0;
     } */

     12% {
       opacity: 1;
     }

     100% {
       opacity: 1;
     }
   }

   @keyframes web-blue-blink {
     0% {
       opacity: 1;
     }

     3% {
       opacity: 0;
     }

     6% {
       opacity: 1;
     }

     9% {
       opacity: 0;
     }

     12% {
       opacity: 1;
     }

     100% {
       opacity: 1;
     }
   }

  /***************************************
   Animated elements
   */

  & .pink-dot {
    animation: web-pink-blink 6s 1.20s ease infinite both;
  }

  & .blue-dot {
    animation: web-blue-blink 6s 3.20s ease infinite both;
  }
`;

function WebIcon() {
  return (
    <AnimatedSvg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="39"
      height="48"
      viewBox="0 0 39 48"
    >
      <defs>
        <path
          id="web-icon-path"
          d="M21.273 40a1.191 1.191 0 01-1.182-1.2c0-.663.529-1.2 1.182-1.2h3.545v-2.4h-8.273C14.587 35.2 13 33.588 13 31.6v-12c0-1.988 1.587-3.6 3.545-3.6h18.91C37.413 16 39 17.612 39 19.6v12c0 1.988-1.587 3.6-3.545 3.6H27.18v2.4h3.546c.612 0 1.115.472 1.176 1.077l.006.123c0 .663-.529 1.2-1.182 1.2zm14.182-21.6h-18.91c-.652 0-1.181.537-1.181 1.2v12c0 .663.529 1.2 1.181 1.2h18.91c.652 0 1.181-.537 1.181-1.2v-12c0-.663-.529-1.2-1.181-1.2z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-602 -862)">
          <g transform="translate(602 862)">
            <circle className="pink-dot" cx="4" cy="44" r="4" fill="#F75252"></circle>
            <circle className="blue-dot" cx="26" cy="4" r="4" fill="#525DF4"></circle>
            <mask fill="#fff">
              <use xlinkHref="#web-icon-path"></use>
            </mask>
            <use fill="#FFF" fillRule="nonzero" xlinkHref="#web-icon-path"></use>
          </g>
        </g>
      </g>
    </AnimatedSvg>
  );
}

export default WebIcon;
