import React from "react"
import styled from "styled-components"

import DotBackground from '../components/dotbackground';

import customers from '../data/customers';




const CustomersSection = styled.div`
  position         : relative;
  background-color : #FFFFFF;
  padding-top      : 11rem;
  padding-bottom   : 11rem;
  overflow         : hidden;
  min-height       : 10rem;
  display          : flex;
  flex-direction   : column;
  justify-content  : center;
  align-items      : center;
  border-bottom    : 1px solid rgba(1,1,1,0.1);
  width            : 100%;
  overflow         : visible;
`;

const CustomersSectionBackground = styled(DotBackground)`
  position : absolute;
  right    : 10%;
  top      : 20rem;
  width    : 50%;
  height   : 100%;
`;

const Title = styled.h2`
  color : black;
  @media screen and (max-width: 425px) {
    width      : 75%;
    text-align : center;
  }
`;

const CustomersRow = styled.div`
  display         : flex;
  justify-content : space-between;
  width           : 100%;
  overflow-x        : hidden;
  padding-bottom  : 3rem;
  & img.top:hover{
    opacity : 0;
  }
  @media screen and (max-width: 800px) {
    flex-direction : column;
  }
`;

const CustomerScrollHandler= styled.div`
  display : flex;
  @media screen and (max-width : 425px) {
    margin-top : 3rem;
    /*overflow-x : auto;
    & .first-row-customers{
      display : none;
    }*/

    & .appero{
      margin-left : 2px;
    }
    &::-webkit-scrollbar{
      display : none;
    }
  }
`;

const CustomerScrolling = styled.div`
  display       : flex;
  position      : relative;
  margin-bottom : 1rem;
  animation     : scrolling 120s linear infinite;
  @keyframes scrolling {
    0%   { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
  }
  @media screen and (max-width : 425px) {
    /*animation : none;*/
    animation-duration : 60s;
    margin-top : 3rem;
  }
`;

const CustomerContainer = styled.div`
  position       : relative;
  margin-right   : 40px;
  display        : flex;
  flex-direction : column;
  align-items    : center;
  outline : none;
  &:hover .tmm{
    background-color : rgb(254, 202, 23);
  }
  &:hover .appero{
    background-color : #132c4e;
  }
  &:hover .qdc{
    filter : invert(1);
    background-color : white;
  }
  &:hover .starbolt{
    background-color : rgb(28.5, 25.8, 43.2);
  }
  &:hover img{
    filter : none;
  }
  &:hover .title{
    opacity            : 1;
    transform          : translateY(2rem);
    transition-duration: .25s;
    color              : black;
  }
  @media screen and (max-width: 425px) {
    margin-right : 20px;
    
    & .appero {
      padding-left : 0;
      padding-right: 0;
      width        : 49.2833
    }
    &:focus .title{
      outline             : none;
      opacity             : 1;
      transform           : translateY(1rem);
      transition-duration : .25s;
      color               : black;
    }
    &:focus img {
      outline: none;
      filter : none;
    }
    &:focus .tmm{
      background-color : rgb(254, 202, 23);
    }
  
    &:focus .appero{
      background-color : #132c4e;
    }
  
    &:focus .qdc{
      filter : invert(1);
      background-color : white;
    }
  
    &:focus .starbolt{
      background-color : rgb(28.5, 25.8, 43.2);
    }
  }
  
`;

const CustomerLogo = styled.img`
  position        : relative;
  display         : inline-flex;
  padding         : 1.3rem;
  width           : 12.5rem;
  height          : 12.5rem;
  overflow        : hidden;
  flex-direction  : column;
  justify-content : center;
  align-items     : center;
  transition      : transform 2s ease, background .25s;
  filter          : brightness(0%);
  object-fit      : contain;
  &.appero {
    padding       : 0;
  }
  @media screen and (max-width: 425px) {
    width         : 15rem;
    height        : 15rem;
    margin-bottom : 3rem;
  }
`;


const CustomerTitle = styled.div`
  background          : rgba(255,255,255);
  position            : absolute;
  bottom              : 0px;
  text-align          : center;
  background          : transparent;
  font-weight         : bolder;
  opacity             : 0;
  transition-duration : 1s;
  display             : flex;
  white-space         : nowrap;
  justify-content     : center;
  font-size           : 1.3rem;
  @media screen and (max-width: 425px) {
    width : 75%;
    font-size : 2rem;
  }
`;

const Customers = props => (
    <CustomersSection id="scrolling-customers">
      <CustomersSectionBackground/>
      <Title>Ils nous font confiance</Title>
      <CustomersRow>
          <CustomerScrollHandler>
            <CustomerScrolling className="first-row-customers">
            {
              customers.map((customer) => (
                <CustomerContainer key={customer.title} tabIndex="0">
                  <CustomerLogo className= {`${customer.className}`} src={customer.picture} />
                  <CustomerTitle className="title">{customer.title}</CustomerTitle>
                </CustomerContainer>
              ))
            }
            </CustomerScrolling>
            <CustomerScrolling className="second-row-customers">
            {
              customers.map((customer) => (
                <CustomerContainer key={customer.title}  tabIndex="0">
                  <CustomerLogo className= {`${customer.className}`} src={customer.picture} />
                  <CustomerTitle className="title">{customer.title}</CustomerTitle>
                </CustomerContainer>
              ))
            }
            </CustomerScrolling>
          </CustomerScrollHandler>
        </CustomersRow>
    </CustomersSection> 
);

export default Customers;