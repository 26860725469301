import React from "react";
import styled from "styled-components"

const AnimatedSvg = styled.svg`
  margin-top    : 8px;
  margin-bottom : 8px;
  margin-left   : -8px;

  /***************************************
   Animations keyframes
   */

  @keyframes cto-pink-blink {
    0% {
      opacity: 1;
    }

    3% {
      opacity: 0;
    }

    6% {
      opacity: 1;
    }

    9% {
      opacity: 0;
    }

    12% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes cto-blue-blink {
    0% {
      opacity: 1;
    }

    3% {
      opacity: 0;
    }

    6% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  /***************************************
   Animated elements
   */

  & .pink-dot {
    animation: cto-pink-blink 6s 4.1s ease infinite both;
  }

  & .blue-dot {
    animation: cto-blue-blink 6s ease infinite both;
  }
`;


function CtoIcon() {
  return (
    <AnimatedSvg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="51"
      height="32"
      viewBox="0 0 51 32"
    >
      <defs>
        <path
          id="cto-icon-path"
          d="M22.5 18.4c3.337 0 6.042 2.686 6.042 6v2.4c0 .663-.541 1.2-1.209 1.2a1.204 1.204 0 01-1.208-1.2v-2.4c0-1.988-1.623-3.6-3.625-3.6h-8.458c-2.002 0-3.625 1.612-3.625 3.6v2.4c0 .663-.541 1.2-1.209 1.2A1.204 1.204 0 018 26.8v-2.4c0-3.314 2.705-6 6.042-6zm9.667-8.4c.667 0 1.208.537 1.208 1.2v2.4h2.417c.625 0 1.14.472 1.202 1.077L37 14.8c0 .663-.541 1.2-1.208 1.2h-2.417v2.4c0 .621-.475 1.132-1.085 1.194l-.123.006a1.204 1.204 0 01-1.209-1.2V16h-2.416c-.626 0-1.14-.472-1.202-1.077l-.007-.123c0-.663.541-1.2 1.209-1.2h2.416v-2.4c0-.621.476-1.132 1.085-1.194zM18.27 4c3.337 0 6.041 2.686 6.041 6s-2.704 6-6.041 6-6.042-2.686-6.042-6 2.705-6 6.042-6zm0 2.4c-2.002 0-3.625 1.612-3.625 3.6s1.623 3.6 3.625 3.6 3.625-1.612 3.625-3.6-1.623-3.6-3.625-3.6z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1031 -874)">
          <g transform="translate(1031 874)">
            <circle className="pink-dot" cx="47" cy="28" r="4" fill="#F75252"></circle>
            <circle className="blue-dot" cx="4" cy="4" r="4" fill="#525DF4"></circle>
            <mask fill="#fff">
              <use xlinkHref="#cto-icon-path"></use>
            </mask>
            <use fill="#FFF" fillRule="nonzero" xlinkHref="#cto-icon-path"></use>
          </g>
        </g>
      </g>
    </AnimatedSvg>
  );
}

export default CtoIcon;
