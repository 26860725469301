import React from 'react';
import styled from 'styled-components';

import { behaviourTagWrapper } from '../shared/analytics';

const Container = styled.div`
  position : relative;
  display : flex;
  flex-direction : row;
  justify-content : center;
  overflow-x : auto;
`;

const ScrollContainer = styled.div`
  position : relative;
  max-width : 100%;
  display : flex;
  flex-direction : row;
  justify-content : flex-start;
  overflow-x : auto;

  @media screen and (max-width : 425px) {
    width : 100%;
    &::-webkit-scrollbar{
      display : none;
    }
  }
`;

const Choice = styled.div`
  border-bottom : 4px solid #E7EAEE;
  width : 20rem;
  min-width : 20rem;
  transition : font-weight 0.2s linear, color 0.2s linear, border-bottom 0.2s linear;
  cursor : pointer;
  outline : none;

  &:hover, &:focus {
    color : #525DF4;
  }

  @media screen and (max-width : 425px) {
    width : 40%;
    min-width : 40%;
  }
`;

const ChoiceText = styled.div.attrs(({selected}) => ({
  style : selected ? {
    fontWeight : 800,
    color : '#525DF4'
  } : {}
}))`
  font-size : 1.8rem;
  text-align : center;
  padding-bottom : 2.1rem;
  line-height : 3rem;
`;

const ChoiceBottom = styled.div`
  position : absolute;
  left : 0;
  width : 20rem;
  height : 4px;
  background-color : #525DF4;
  top : 5.1rem;
  transition : left 0.3s ease, transform 0.3s ease;

  &.selected0 { transform : translateX(0rem);}
  &.selected1 { transform : translateX(20rem);}
  &.selected2 { transform : translateX(40rem);}
  &.selected3 { transform : translateX(60rem);}
  &.selected4 { transform : translateX(80rem);}
  &.selected5 { transform : translateX(100rem);}
  &.selected6 { transform : translateX(120rem);}
  &.selected7 { transform : translateX(140rem);}

  @media screen and (max-width : 425px) {
    width : 40%;

    &.selected0 { transform : translateX(0rem); left : 0%;}
    &.selected1 { transform : translateX(0rem); left : 40%}
    &.selected2 { transform : translateX(0rem); left : 80%;}
    &.selected3 { transform : translateX(0rem); left : 120%;}
    &.selected4 { transform : translateX(0rem); left : 160%;}
    &.selected5 { transform : translateX(0rem); left : 200%;}
    &.selected6 { transform : translateX(0rem); left : 240%;}
    &.selected7 { transform : translateX(0rem); left : 280%;}
  }
`;

const CarouselHeader = ({choices = [], selected = 0, onSelect}) => {
  const analyticsOnSelect = behaviourTagWrapper('references-carousel');

  return (
    <Container>
      <ScrollContainer>
        {
          choices.map((choice, index) => (
            <Choice tabIndex="0" key={choice} onClick={analyticsOnSelect(() => onSelect(index))} onKeyPress={analyticsOnSelect(() => onSelect(index))}>
              <ChoiceText selected={selected === index}>
                {choice}
              </ChoiceText>
            </Choice>
          ))
        }
        <ChoiceBottom className={`selected${selected}`}/>
      </ScrollContainer>
    </Container>
  );
};

export default CarouselHeader;
