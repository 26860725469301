import React from "react";
import styled from "styled-components"

const AnimatedSvg = styled.svg`
  margin-top : 3px;

  /***************************************
   Animations keyframes
   */

   @keyframes mobile-blue-blink {
     0% {
       opacity: 1;
     }

     /* 3% {
       opacity: 0;
     } */

     6% {
       opacity: 0;
     }

     /* 9% {
       opacity: 0;
     } */

     12% {
       opacity: 1;
     }

     100% {
       opacity: 1;
     }
   }

   @keyframes mobile-pink-blink {
     0% {
       opacity: 1;
     }

     3% {
       opacity: 0;
     }

     6% {
       opacity: 1;
     }

     100% {
       opacity: 1;
     }
   }

   /***************************************
    Animated elements
    */

   & .pink-dot {
     animation: mobile-pink-blink 6s 2s ease infinite both;
   }

   & .blue-dot {
     animation: mobile-blue-blink 6s 4.9s ease infinite both;
   }
`;


function MobileIcon() {
  return (
    <AnimatedSvg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="42"
      height="45"
      viewBox="0 0 42 45"
    >
      <defs>
        <path
          id="mobile-icon-path"
          d="M3.75 19.545c-.69 0-1.25.57-1.25 1.273v20.364c0 .703.56 1.273 1.25 1.273h12.5c.69 0 1.25-.57 1.25-1.273V20.818c0-.703-.56-1.273-1.25-1.273H3.75zm0-2.545h12.5c2.071 0 3.75 1.71 3.75 3.818v20.364C20 43.29 18.321 45 16.25 45H3.75C1.679 45 0 43.29 0 41.182V20.818C0 18.71 1.679 17 3.75 17z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-182 -860)">
          <g transform="translate(182 860)">
            <circle className="pink-dot" cx="38" cy="31" r="4" fill="#F75252"></circle>
            <circle className="blue-dot" cx="20" cy="4" r="4" fill="#525DF4"></circle>
            <mask fill="#fff">
              <use xlinkHref="#mobile-icon-path"></use>
            </mask>
            <use fill="#FFF" fillRule="nonzero" xlinkHref="#mobile-icon-path"></use>
          </g>
        </g>
      </g>
    </AnimatedSvg>
  );
}

export default MobileIcon;
