import React from "react"
import styled from "styled-components"

import arrow from '../images/arrow-down.svg';

const Container = styled.div`
  position : absolute;
  bottom : 0;
  width : 100%;
  display : flex;
  flex-direction : row;
  justify-content : center;
  align-items : center;
  padding-bottom : 5rem;
  transition : opacity 0.4s ease-in-out;

  &.hidden {
    opacity : 0;
  }

  @media screen and (min-width: 426px) {
    display : none;
  }
`;

const Arrow = styled.img`
  @keyframes scroll-move {
    0% {
      transform : translateY(0rem);
    }

    50% {
      transform : translateY(-1rem);
    }

    100% {
      transform : translateY(0rem);
    }
  }

  animation : scroll-move 1s ease-in-out infinite both;
`

const ScrollDown = ({hidden = false, onClick}) => (
  <Container className={hidden ? 'hidden' : ''} onClick={onClick}>
    <Arrow src={arrow}/>
  </Container>
)

export default ScrollDown;
