const customersDb = {
    tellmemore : {
        picture : require('../images/customers/tmm3.png'),
        title : 'Tell me more',
        className : 'tmm'
    },
    dgfip : {
        picture : require('../images/customers/logo-dgfip-square.png'),
        title : 'DGFIP',
        className : 'dgfip'
    },
    sodexo : {
        picture : require('../images/customers/logo-sodexo.png'),
        title : 'Sodexo',
        className : 'sodexo'
    },
    wxsolutions : {
        picture : require('../images/customers/logo-wx.png'),
        title : 'WX solutions',
        className : 'wxsolutions'
    },
    caf : {
        picture : require('../images/customers/logo-caf-png.png'),
        title : 'CAF',
        className : 'caf'
    },
    mytiboo : {
        picture : require('../images/customers/logo-mytiboo3-reduit.png'),
        title : 'My Tiboo',
        className : 'mytiboo'
    },
    starbolt : {
        picture : require('../images/customers/logo-starbolt2.png'),
        title : 'Starbolt',
        className : 'starbolt'
    },
    elior : {
        picture : require('../images/customers/logo-elior.png'),
        title : 'Elior',
        className : 'elior'
    },
    saretec : {
        picture : require('../images/customers/logo-saretec.png'),
        title : 'Saretec',
        className : 'saretec'
    },
    appero : {
        picture : require('../images/customers/logo-appero.png'),
        title : 'Appero',
        className : 'appero'
    },
    ore : {
        picture : require('../images/customers/logo-ore.png'),
        title : 'Agence ORE',
        className : 'ore'
    },
    planifeye : {
        picture : require('../images/customers/logo-planifeye.png'),
        title : 'Planifeye',
        className : 'planifeye'
    },
    quadratureducercle : {
        picture : require('../images/customers/logo-qdc1.png'),
        title : 'Quadrature du Cercle',
        className : 'qdc'
    },
    hkind : {
        picture : require('../images/customers/logo-hkind.png'),
        title : 'Hkind',
        className : 'hkind'
    }
}

export default [
    customersDb.appero,
    customersDb.saretec,
    customersDb.starbolt,
    customersDb.wxsolutions,
    customersDb.sodexo,
    customersDb.dgfip,
    customersDb.elior,
    customersDb.caf,
    customersDb.mytiboo,
    customersDb.ore,
    customersDb.tellmemore,
    customersDb.hkind,
    customersDb.quadratureducercle,
    customersDb.planifeye,
];